// import { faCalendar } from "@fortawesome/free-regular-svg-icons";
// import {
//   faCalendarAlt,
//   faCalendarTimes,
//   faMapMarkerAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import Img from "../assets/images/jalwa-event-small-banner.jpeg";
// import Layout from "../Components/Layout/Layout";

const ticket = ({ location }) => {
  return (
    <>
      {/* <Layout location={location}>
        <div className="max-w-6xl mx-auto">
          <div className="w-full">
            <div>
              <img src={Img} />
            </div>
            <div className="bg-secondary-darkpurple-500 p-3">
              <h1 className="text-3xl text-white pb-1">
                Badshah Live in Goa - Amethhyyst Jalwaa Open Arena
              </h1>
              <div className="text-sm text-white pb-1">
                <FontAwesomeIcon icon={faCalendar} />

                <span className="pl-1.5">
                  Thursday, 9th Dec 2021 - Friday, 10th Dec 2021 | 04:02 PM to
                  03:03 AM IST
                </span>
              </div>
              <div className="text-sm text-white">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span className="pl-1.5">
                  Toy beach club,Anna waddoo, Candolim, Goa, India
                </span>
              </div>
            </div>
          </div>
          <div className="mt-5 grid grid-cols-12">
            <div className="col-span-7"></div>
            <div className="col-span-5"></div>
          </div>
        </div>
      </Layout> */}
    </>
  );
};

export default ticket;
